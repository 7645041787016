import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import Header from '../components/header.js'
import Footer from '../components/footer.js'

const IndexPage = ({ data }) => (
  <div className="page">
    <div className="container">
      <Helmet title={data.site.siteMetadata.title} />
      <Header
        siteTitle={data.site.siteMetadata.title}
        siteSubTitle={data.site.siteMetadata.subtitle}
      />
      <div className="top">
        <div className="top-menu">
          <Link to="blog/about/" className="about">
            <i className=" icon-rocket" />
            <div className="label">ABOUT</div>
          </Link>
          <Link to="blog/" className="blog">
            <i className=" icon-pencil" />
            <div className="label">BLOG</div>
          </Link>
          <Link to="blog/series/" className="series">
            <i className=" icon-list" />
            <div className="label">SERIES</div>
          </Link>
          <Link to="blog/memo/" className="memo">
            <i className=" icon-new-message" />
            <div className="label">MEMO</div>
          </Link>
        </div>
      </div>
    </div>
    <Footer
      copyright={data.site.siteMetadata.copyright}
      repositoryName={data.site.siteMetadata.repositoryName}
      repositoryLink={data.site.siteMetadata.repositoryLink}
    />
  </div>
)

IndexPage.propTypes = {
  data: PropTypes.object,
}

export default IndexPage

export const query = graphql`
  query SiteIndexTitleQuery {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        repositoryName
        repositoryLink
      }
    }
  }
`
